<template>
  <div class="container pb-5">
    <Spinner v-if="loading" />
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div v-if="!loading && user" class="parent">
      <div class="d-flex justify-content-between">
        <h1>Files</h1>
        <button class="btn btn-primary" :disabled="updating" @click="updateFilesStatus">
          Update Files Status
          <b-spinner v-if="updating" small label="Spinning" variant="white"></b-spinner>
        </button>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th>Name</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody v-if="user.files">
          <tr v-for="(file, key) in user.files" :key="key">
            <td>{{ file.id }}</td>
            <td>
              <a :href="file.url" target="_blank">{{ file.name }}</a>
            </td>
            <td>{{ file.type }}</td>
            <td>
              <span class="badge badge-warning" v-if="file.status == 'submitted'"
                >Submitted</span
              >
              <span class="badge badge-success" v-if="file.status == 'approved'"
                >Approved</span
              >
              <span class="badge badge-danger" v-if="file.status == 'rejected'"
                >Rejected</span
              >
            </td>
            <td>
              <b-form-select
                v-model="file.status"
                :options="options"
                size="sm"
              ></b-form-select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../../components/ui/Spinner.vue";

export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      updating: false,
      user: null,
      error: "",
      options: [
        { value: null, text: "select an option" },
        { value: "submitted", text: "Submitted" },
        { value: "approved", text: "Approve" },
        { value: "rejected", text: "Reject" },
      ],
    };
  },
  created() {
    console.log("created");
  },
  mounted() {
    console.log("mounted");
    this.getUser(this.$route.params.id);
  },
  methods: {
    async getUser(user_id) {
      this.loading = true;
      this.error = "";
      try {
        const { data } = await axios.get(`admin/users/${user_id}`);
        this.user = data.user;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error?.response?.data?.message
          ? error?.response?.data?.message
          : error.message;
      }
    },
    updateFilesStatus() {
      this.updating = true;
      this.error = "";
      axios
        .post(`admin/files/update-status`, { files: this.user.files })
        .then(() => {
          this.updating = false;
          this.$bvToast.toast("Status updated successfully", {
            title: `Success`,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          this.updating = false;
          this.error = error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message;
          this.$bvToast.toast(this.error, {
            title: "Something went wrong",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.parent {
  overflow-x: scroll;
}

.custom-select-sm {
  font-size: 0.875rem !important;
}
</style>
